body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.min-50 {
  min-width: 50px;
}
.min-40 {
  min-width: 40px;
}
#header {
  z-index: 100;
}
footer {
  padding: .5rem 0;
}
footer p {
  margin: 0;
  line-height: 1.2em;
}
footer p strong {
  font-size: 1.1em;
}
#playerTimeline {
  width: 73%;
}
#player .small svg {
  margin-left: .25rem;
  margin-right: .25rem;
}
#header {
  position: sticky;
  top: 0;
}

/* Sticky Footer */
html, body, #root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
.app {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
  position: sticky;
  bottom: 0px;
}
